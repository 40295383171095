export const phone = /^1[3-9]\d{9}$/
export const password = /^[a-zA-Z][a-zA-Z0-9@#$%^\-&*()._+=]{5,15}$/
export const number = /^\d+$/
export const integer = /^[-+]?\d+$/
export const float = /^[-+]?\d+\.?\d*$/
// export const float1 = /^\d+(\.\d{2})?$/
export const float1 = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/
export const float2 = /^(([1-9][0-9]*)|(([0]\.\d{1,8}|[1-9][0-9]*\.\d{1,8})))$/
export const date = /^20\d{2}-\d{2}-\d{2}$/
export const alpha = /^[a-zA-Z]+$/
