<template>
    <div class="bg-white box-shadow border-r px-20 pb-20">
        <div class="d-flex-center px-15 border-bottom">
            <h4 class="mr-auto color-333">{{ $t('exchange.title') }}</h4>
            <el-button type="primary" size="mini" icon="el-icon-refresh" plain @click="fatchData()">{{ $t('base.refresh') }}</el-button>
        </div>
        <div v-loading="loading" class="p-20 border-bottom">
            <el-form
                ref="ruleForm"
                class="input-form mx-auto"
                :model="formData"
                :rules="rules"
            >
                <el-form-item>
                    <div class="d-flex-center">
                        <el-select v-model="formData.coin" prefix-icon="el-icon-money" placeholder="Select Address" class="w-100" @change="bindFromTabChange()">
                            <el-option v-for="(item, index) in coinList" :key="index" :label="item" :value="item" />
                        </el-select>
                        <i class="el-icon-d-arrow-right" style="margin-left: 20px; margin-right: 20px;" @click="switchType()" />
                        <el-select v-model="formData.pair" prefix-icon="el-icon-money" placeholder="Select Address" class="w-100" @change="bindToTabChange()">
                            <el-option v-for="(item, index) in coinList" :key="index" :label="item" :value="item" />
                        </el-select>
                    </div>
                </el-form-item>
                <el-form-item>
                    <div class="d-flex-center font-16">
                        <span class="mr-auto">{{ $t('available') }} ({{ formData.coin }})</span>
                        <span>{{ info.balance }}</span>
                    </div>
                </el-form-item>
                <el-form-item prop="amount">
                    <div class="d-flex-center">
                        <el-input v-model="formData.amount" prefix-icon="el-icon-money" :placeholder="$t('amount')">
                            <template slot="suffix">
                                <span class="mx-5">{{ formData.coin }}</span>
                            </template>
                        </el-input>
                        <el-button type="primary" plain @click="setMax()">{{ $t('MAX') }}</el-button>
                    </div>
                </el-form-item>
                <el-form-item>
                    <el-input :value="info.price * 1" readonly prefix-icon="el-icon-coin" :placeholder="$t('price')">
                        <template slot="suffix">
                            <span class="mx-5">{{ formData.coin }} / {{ formData.pair }}</span>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-input :value="total" readonly prefix-icon="el-icon-coin" :placeholder="$t('total')">
                        <template slot="suffix">
                            <span class="mx-5">{{ formData.pair }}</span>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-s-promotion" class="w-100" native-type="button" @click="submitForm()">{{ $t('submit') }}</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div v-loading="loadingRecord">
            <div class="d-flex-center px-15 border-bottom">
                <h4 class="mr-auto color-333">{{ $t('exchange.history.title') }}</h4>
                <el-button type="primary" size="mini" icon="el-icon-refresh" plain @click="fatchRecord()">{{ $t('base.refresh') }}</el-button>
            </div>
            <el-row :gutter="20" type="flex" justify="center" align="middle" class="color-gray px-20 py-15 border-bottom font-14">
                <el-col :span='3'>Order ID</el-col>
                <el-col :span='4'>Coin/Pair</el-col>
                <el-col :span='4'>Amount</el-col>
                <el-col :span='4'>Price</el-col>
                <el-col :span='4'>Total</el-col>
                <el-col :span='5'>Date Time</el-col>
            </el-row>
            <el-row v-for="(item, index) in list.data" :key="index" :gutter="20" type="flex" justify="center" align="middle" class="px-20 py-15 border-bottom font-14">
                <el-col :span='3'>{{ item.id }}</el-col>
                <el-col :span='4'>{{ item.coin }} -> {{ item.pair }}</el-col>
                <el-col :span='4'>{{ item.amount }}</el-col>
                <el-col :span='4'>{{ item.price }}</el-col>
                <el-col :span='4'>{{ item.total }}</el-col>
                <el-col :span='5'>{{ item.create_time }}</el-col>
            </el-row>
            <div class="d-flex-center pt-20">
                <el-pagination
                    :hide-on-single-page="true"
                    background
                    layout="prev, pager, next"
                    :page-size="list.per_page"
                    :current-page="list.current_page"
                    :total="list.total"
                    @current-change="onPage"
                />
            </div>
        </div>
    </div>
</template>

<script>
import coinLogo from '@/assets/coin/logo'
import { float as regexpFloat } from '@/services/regexp'
export default {
    data() {
        return {
            coinLogo,
            coinList: [
                'USDT',
                'BTC',
                'ETH',
                'LTC'
            ],
            info: {
                balance: '0.00000000',
                price: '0.00000000'
            },
            verifyState: [
                'Unsubmitted',
                'Fail',
                'Pending',
                'Success'
            ],
            loading: false,
            formData: {
                coin: 'USDT',
                pair: 'BTC',
                amount: ''
            },
            loadingRecord: false,
            query: { ...this.$route.query },
            list: {
                per_page: 1,
                current_page: 1,
                total: 0,
                data: []
            },
            state: [
                ['primary', 'Checking'],
                ['success', 'Success'],
                ['danger', 'Fail'],
                ['info', 'Cancel']
            ],
            timer: null,
            rules: {
                amount: [
                    { required: true, pattern: regexpFloat, trigger: 'blur', message: this.$t('wallet.pea') }
                ]
            }
        }
    },
    computed:{
        total() {
            if(this.formData.amount === '') return ''
            return (this.formData.amount / this.info.price).toFixed(8) * 1
        }
    },
    watch: {
        $route: function (to, from){
            if(to.params.coin === from.params.coin) return
            this.fatchData()
            this.fatchRecord()
        }
    },
    created: function() {
        this.fatchData()
        this.fatchRecord()
    },
    methods: {
        bindFromTabChange: function() {
            if(this.formData.coin == this.formData.pair){
                this.formData.pair = this.formData.coin == 'USDT' ? 'BTC' : 'USDT'
            }
            this.formData.amount = ''
            this.fatchData()
        },
        bindToTabChange: function() {
            if(this.formData.coin == this.formData.pair){
                this.formData.coin = this.formData.pair == 'USDT' ? 'BTC' : 'USDT'
            }
            this.fatchData()
        },
        setMax: function(){
            this.formData.amount = this.info.balance <= 0 ? '' : this.info.balance * 1
        },
        switchType: function(){
            console.log('switchType')
            this.formData.amount = ''
            const coin = this.formData.coin
            const pair  = this.formData.pair
            this.formData.pair = coin
            this.formData.coin = pair
            this.fatchData()
        },
        fatchData: async function () {
            this.loading = true
            const { data } = await this.$request.post('exchange/info', this.formData)
            this.info = data
            for(const coin of data.coin_list){
                if(this.coinList.indexOf(coin) < 0){
                    this.coinList.push(coin)
                }
            }
            this.loading = false
        },
        submitForm: async function () {
            try {
                await this.$refs.ruleForm.validate()
                this.loading = true
                await this.$request.post('exchange', this.formData)
                this.formData.amount = ''
                this.fatchData()
                this.fatchRecord()
            } catch (error) {
                this.loading = false
            }
        },
        fatchRecord: async function () {
            try {
                this.loadingRecord = true
                const { data } = await this.$request.get('exchange/log_pc')
                this.list = data
                this.loadingRecord = false
            } catch (error) {
                this.loadingRecord = false
            }
        },
        onSearch() {
            this.fatchRecord()
            this.$router.replace({
                query: this.query
            })
        },
        onPage(page) {
            this.query.page = page || 1
            this.onSearch()
        }
    }
}
</script>
<style>
.el-input-group__appen{
    background-color: transparent !important;
}
</style>
<style lang="scss" scoped>
.input-form{
    width: 400px;
}
</style>